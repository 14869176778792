/*
 * @Author: c.k
 * @Date: 2022-11-03 09:05:11
 * @LastEditors: c.k
 * @LastEditTime: 2022-11-03 11:16:30
 * @Descripttion:
 */
import http from '../utils/http';
//
/**
 *  @parms resquest 请求地址 例如：http://197.82.15.15:8088/request/...
 *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
 */

// get请求
const testApi = {

  login(data) {
    return http.post('/v2/user/public/login/phone',data)
  },
  check(data) {
    return http.post('/v2/public/common/sms',data)
  }

};

export default testApi;
